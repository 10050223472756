import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureFirebase from './configure-firebase.js';

configureFirebase().then(async () => {
  const App = await import('./App.js');
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    // eslint-disable-next-line react/jsx-pascal-case
    <App.default />,
  );
});

serviceWorkerRegistration.unregister();